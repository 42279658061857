import axios from 'axios';
import Raven from 'raven-js';
import { getJwtToken } from '../util/session';
import config from '../util/config';

const endpoint = config.API_ENDPOINT;

const getAuthHeaders = () => ({ Authorization: `Bearer ${getJwtToken()}` });

axios.interceptors.response.use(
  (cnf) => cnf,
  // eslint-disable-next-line consistent-return
  (error) => {
    if (
      !error ||
      !error.response ||
      error.response.status === 408 ||
      error.code === 'ECONNABORTED' ||
      error.response.status >= 500
    ) {
      Raven.captureException(error);
      return Promise.reject(error);
    } else if (error.response.status === 401) {
      window.location = '/signin';
      return Promise.reject(new Error('Unauthorized'));
    }

    return Promise.reject(error);
  }
);

export default {
  clientGet(companyId, clientId, cancelToken = null) {
    return axios.get(`${endpoint}/api/company/${companyId}/client/${clientId}`, {
      cancelToken,
      headers: getAuthHeaders(),
    });
  },
  clientAllChatsGet(companyId, params) {
    return axios.get(`${endpoint}/api/client/${companyId}/chat`, {
      headers: getAuthHeaders(),
      params: { ...params },
    });
  },
  // It used for company and project chat as project permissions should be checked anyway
  clientChatGet(companyId, chatId, params) {
    return axios.get(`${endpoint}/api/client/${companyId}/chat/${chatId}`, {
      headers: getAuthHeaders(),
      params: { ...params },
    });
  },
  clientProjectChatsGet(companyId, projectId, params) {
    return axios.get(`${endpoint}/api/client/${companyId}/project/${projectId}/chat`, {
      headers: getAuthHeaders(),
      params: { ...params },
    });
  },
  chatUserReadPut(chatId) {
    return axios.put(
      `${endpoint}/api/chat/${chatId}/user/read`,
      {},
      {
        headers: getAuthHeaders(),
      }
    );
  },
  messagePost(chatId, data) {
    return axios.post(`${endpoint}/api/chat/${chatId}/message`, data, {
      headers: getAuthHeaders(),
    });
  },
  messagesGet(companyId, chatId, params) {
    return axios.get(`${endpoint}/api/company/${companyId}/chat/${chatId}/message`, {
      headers: getAuthHeaders(),
      params: { ...params },
    });
  },
  companyPost(data) {
    return axios.post(`${endpoint}/api/company`, data, {
      headers: getAuthHeaders(),
    });
  },
  companyPut(data) {
    return axios.put(`${endpoint}/api/company`, data, {
      headers: getAuthHeaders(),
    });
  },
  companyUsersGet(companyId, cancelToken = null) {
    return axios.get(`${endpoint}/api/company/${companyId}/user`, {
      cancelToken,
      headers: getAuthHeaders(),
    });
  },
  companyEmailInvites(emails, body) {
    return axios.post(
      `${endpoint}/api/company/email/invite`,
      { emails, body },
      {
        headers: getAuthHeaders(),
      }
    );
  },
  companyfavoritePut(companyId, data) {
    return axios.put(`${endpoint}/api/company/${companyId}/favoriteConnectedCompany`, data, {
      headers: getAuthHeaders(),
    });
  },
  companyGetNewMessagesCount(companyId) {
    return axios.get(`${endpoint}/api/company/${companyId}/new-messages-count`, {
      headers: getAuthHeaders(),
    });
  },
  productPost(companyId, projectId, data) {
    return axios.post(`${endpoint}/api/company/${companyId}/project/${projectId}/product`, data, {
      headers: getAuthHeaders(),
    });
  },
  productPut(companyId, projectId, data) {
    return axios.put(`${endpoint}/api/company/${companyId}/project/${projectId}/product`, data, {
      headers: getAuthHeaders(),
    });
  },
  productsGet(companyId, projectId) {
    return axios.get(`${endpoint}/api/company/${companyId}/project/${projectId}/product`, {
      headers: getAuthHeaders(),
    });
  },
  productGet(companyId, projectId, productId, cancelToken = null) {
    return axios.get(
      `${endpoint}/api/company/${companyId}/project/${projectId}/product/${productId}`,
      {
        cancelToken,
        headers: getAuthHeaders(),
      }
    );
  },
  productQuotesGet(productId, cancelToken = null) {
    return axios.get(`${endpoint}/api/product/${productId}/quote`, {
      cancelToken,
      headers: getAuthHeaders(),
    });
  },
  quoteRequestProductGet(productId, cancelToken = null) {
    return axios.get(`${endpoint}/api/quote/request/${productId}`, {
      cancelToken,
      headers: getAuthHeaders(),
    });
  },
  quoteSupplierPost(companyId, data) {
    return axios.post(`${endpoint}/api/supplier/${companyId}/quote`, data, {
      headers: getAuthHeaders(),
    });
  },
  quotesSupplierGet(companyId, cancelToken = null) {
    return axios.get(`${endpoint}/api/supplier/${companyId}/quote`, {
      cancelToken,
      headers: getAuthHeaders(),
    });
  },
  quoteSupplierGet(companyId, quoteId, cancelToken = null) {
    return axios.get(`${endpoint}/api/supplier/${companyId}/quote/${quoteId}`, {
      cancelToken,
      headers: getAuthHeaders(),
    });
  },
  quoteSupplierPut(companyId, data) {
    return axios.put(`${endpoint}/api/supplier/${companyId}/quote`, data, {
      headers: getAuthHeaders(),
    });
  },
  quoteFavoritePut(quoteId, data) {
    return axios.put(`${endpoint}/api/quote/${quoteId}/favorite/quantity`, data, {
      headers: getAuthHeaders(),
    });
  },
  quoteGet(productId, quoteId, cancelToken = null) {
    return axios.get(`${endpoint}/api/product/${productId}/quote/${quoteId}`, {
      cancelToken,
      headers: getAuthHeaders(),
    });
  },
  productSendEmail(companyId, projectId, productId, data) {
    return axios.post(
      `${endpoint}/api/company/${companyId}/project/${projectId}/product/${productId}/email`,
      data,
      {
        headers: getAuthHeaders(),
      }
    );
  },
  projectsGetByCompanyId(companyId) {
    return axios.get(`${endpoint}/api/company/${companyId}/project`, {
      headers: getAuthHeaders(),
    });
  },
  projectsGetNewMessagesCountByCompanyId(companyId) {
    return axios.get(`${endpoint}/api/company/${companyId}/project/new-messages-count`, {
      headers: getAuthHeaders(),
    });
  },
  projectPost(companyId, data) {
    return axios.post(`${endpoint}/api/company/${companyId}/project`, data, {
      headers: getAuthHeaders(),
    });
  },
  projectPut(companyId, data, cancelToken = null) {
    return axios.put(`${endpoint}/api/company/${companyId}/project`, data, {
      cancelToken,
      headers: getAuthHeaders(),
    });
  },
  suppliersGet(companyId, projectId, cancelToken = null, params) {
    return axios.get(`${endpoint}/api/company/${companyId}/project/${projectId}/supplier`, {
      cancelToken,
      headers: getAuthHeaders(),
      params: { ...params },
    });
  },
  supplierAllChatsGet(companyId, params) {
    return axios.get(`${endpoint}/api/supplier/${companyId}/chat`, {
      headers: getAuthHeaders(),
      params: { ...params },
    });
  },
  supplierChatGet(companyId, chatId, params) {
    return axios.get(`${endpoint}/api/supplier/${companyId}/chat/${chatId}`, {
      headers: getAuthHeaders(),
      params: { ...params },
    });
  },
  clientsGet(companyId, cancelToken = null, params) {
    return axios.get(`${endpoint}/api/company/${companyId}/client`, {
      cancelToken,
      headers: getAuthHeaders(),
      params: { ...params },
    });
  },
  userEmailInvites(companyId, emails) {
    return axios.post(
      `${endpoint}/api/user/email/invite`,
      { companyId, emails },
      {
        headers: getAuthHeaders(),
      }
    );
  },
  userCheckByEmail(email) {
    return axios.get(`${endpoint}/api/user/check/${email}`);
  },
  userSignupEmail(user) {
    return axios.post(`${endpoint}/api/user`, user);
  },
  userSignIn(data) {
    return axios.post(`${endpoint}/api/user/login`, data);
  },
  companyUserDelete(companyId, user) {
    return axios.delete(`${endpoint}/api/company/${companyId}/user/${user._id}`, {
      headers: getAuthHeaders(),
    });
  },
  userPut(user) {
    return axios.put(`${endpoint}/api/user`, user, {
      headers: getAuthHeaders(),
    });
  },
  userPutRole(data) {
    return axios.put(`${endpoint}/api/user/role`, data, {
      headers: getAuthHeaders(),
    });
  },
  userPutByInvitedToken(token) {
    return axios.put(
      `${endpoint}/api/user/invited/${token}`,
      {},
      {
        headers: getAuthHeaders(),
      }
    );
  },
  userEmailConfirm(token, cancelToken = null) {
    return axios.put(`${endpoint}/api/user/email/confirm/${token}`, {}, { cancelToken });
  },
  userGet() {
    return axios.get(`${endpoint}/api/user`, {
      headers: getAuthHeaders(),
    });
  },
  userGetCompanies() {
    return axios.get(`${endpoint}/api/user/company`, {
      headers: getAuthHeaders(),
    });
  },
  userGetCompanyByInvitedToken(token) {
    return axios.get(`${endpoint}/api/company/invited/${token}`);
  },
  userEmailResetPasswordLink(email) {
    return axios.post(`${endpoint}/api/user/email/forgot-password`, { email });
  },
  userResetPasswordByToken(password, token) {
    return axios.put(`${endpoint}/api/user/reset-password`, { password, token });
  },
  /**
   * @param {Object} params
   * @param params.objectType e.g. 'companyLogo'
   * @param params.contentType
   * @param params.name
   */
  signS3Put(params = {}) {
    const payload = params;
    if (payload.name) {
      payload.name = encodeURIComponent(payload.name);
    }
    return axios.get(`${endpoint}/api/upload/s3/sign`, {
      params: payload,
      headers: getAuthHeaders(),
    });
  },
  supplierProductQuoteGet(companyId, productId, cancelToken = null) {
    return axios.get(`${endpoint}/api/supplier/${companyId}/product/${productId}/quote`, {
      cancelToken,
      headers: getAuthHeaders(),
    });
  },
  supplierGet(companyId, supplierId, cancelToken = null) {
    return axios.get(`${endpoint}/api/company/${companyId}/supplier/${supplierId}`, {
      cancelToken,
      headers: getAuthHeaders(),
    });
  },
};
