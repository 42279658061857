import { push } from 'connected-react-router';
import _findIndex from 'lodash.findindex';

import {
  signInCompleteUserSuccess,
  signUpEmailRestore,
  signupSetInvitedCompanySuccess,
  signupSetInvitedTokenSuccess,
} from '../sign/actions';
import { appLoading, appFetchingData } from '../../actions';
import {
  DASHBOARD_CHANGE_ACTIVE_COMPANY_ID_SUCCESS,
  DASHBOARD_CHANGE_ACTIVE_PROJECT_ID_SUCCESS,
  DASHBOARD_WS_CHANNEL_COMPANY_SET,
  DASHBOARD_GET_COMPANIES_SUCCESS,
  DASHBOARD_GET_PRODUCTS_SUCCESS,
  DASHBOARD_GET_PROJECTS_SUCCESS,
  DASHBOARD_ADD_PROJECT_SUCCESS,
  DASHBOARD_LEFT_MENU_SET_ACTIVE_ITEM_SUCCESS,
  DASHBOARD_EDIT_PROJECT_SUCCESS,
  DASHBOARD_SET_PROJECTS_NEW_MESSAGES_COUNT_SUCCESS,
  DASHBOARD_RESET_PROJECTS_NEW_MESSAGES_COUNT_SUCCESS,
  DASHBOARD_INC_PROJECTS_NEW_MESSAGES_COUNT_SUCCESS,
  DASHBOARD_REPLACE_PROJECTS_NEW_MESSAGES_COUNT_SUCCESS,
  DASHBOARD_ACTIVE_COMPANY_SET,
  DASHBOARD_SET_COMPANY_NEW_MESSAGES_COUNT_SUCCESS,
  DASHBOARD_INC_COMPANY_NEW_MESSAGES_COUNT_SUCCESS,
} from './constants';
import { EventTypes } from 'redux-segment';
import {
  getProjectPath,
  pathRegExpsDependOnActiveCompany,
  pathRegExpsDependOnActiveProject,
} from '../../util/path';
import {
  getJwtToken,
  sessionGetLastCompanyId,
  sessionGetLastProjectId,
  sessionSetLastCompanyId,
  sessionSetLastProjectId,
} from '../../util/session';
import pusher from '../../service/pusher';

export function dashboardGetUserCompaniesSuccess(companies) {
  return {
    type: DASHBOARD_GET_COMPANIES_SUCCESS,
    companies,
    meta: {
      analytics: EventTypes.track,
    },
  };
}

export function dashboardGetProjectsSuccess(projects) {
  return {
    type: DASHBOARD_GET_PROJECTS_SUCCESS,
    projects,
    meta: {
      analytics: EventTypes.track,
    },
  };
}

export function dashboardAddProjectSuccess(project) {
  return {
    type: DASHBOARD_ADD_PROJECT_SUCCESS,
    project,
    meta: {
      analytics: EventTypes.track,
    },
  };
}

export function dashboardEditProjectSuccess(project) {
  return {
    type: DASHBOARD_EDIT_PROJECT_SUCCESS,
    project,
    meta: {
      analytics: EventTypes.track,
    },
  };
}

export function dashboardGetProductsSuccess(products) {
  return {
    type: DASHBOARD_GET_PRODUCTS_SUCCESS,
    products,
    meta: {
      analytics: EventTypes.track,
    },
  };
}

export function dashboardChangeActiveProjectIdSuccess(projectId) {
  return {
    type: DASHBOARD_CHANGE_ACTIVE_PROJECT_ID_SUCCESS,
    projectId,
    meta: {
      analytics: EventTypes.track,
    },
  };
}

export function dashboardChangeActiveCompanyIdSuccess(companyId) {
  return {
    type: DASHBOARD_CHANGE_ACTIVE_COMPANY_ID_SUCCESS,
    companyId,
    meta: {
      analytics: EventTypes.track,
    },
  };
}

export function dashboardActiveCompanySet(company) {
  return {
    type: DASHBOARD_ACTIVE_COMPANY_SET,
    company,
    meta: {
      analytics: EventTypes.track,
    },
  };
}

export function dashboardLeftMenuSetActiveItemSuccess(name) {
  return {
    type: DASHBOARD_LEFT_MENU_SET_ACTIVE_ITEM_SUCCESS,
    name,
    meta: {
      analytics: EventTypes.track,
    },
  };
}

export function dashboardWsChannelCompanySetSuccess(channel) {
  return {
    type: DASHBOARD_WS_CHANNEL_COMPANY_SET,
    channel,
    meta: {
      analytics: EventTypes.track,
    },
  };
}

export function dashboardSetCompanyNewMessagesCount(count) {
  return {
    type: DASHBOARD_SET_COMPANY_NEW_MESSAGES_COUNT_SUCCESS,
    count,
  };
}

export function dashboardIncCompanyNewMessagesCount(inc) {
  return {
    type: DASHBOARD_INC_COMPANY_NEW_MESSAGES_COUNT_SUCCESS,
    inc,
  };
}

export function dashboardSetProjectsNewMessagesCountSuccess(projectId, count) {
  return {
    type: DASHBOARD_SET_PROJECTS_NEW_MESSAGES_COUNT_SUCCESS,
    projectId,
    count,
  };
}

export function dashboardIncProjectsNewMessagesCountSuccess(projectId, inc) {
  return {
    type: DASHBOARD_INC_PROJECTS_NEW_MESSAGES_COUNT_SUCCESS,
    projectId,
    inc,
  };
}

export function dashboardReplaceProjectsNewMessagesCountSuccess(projectsMessagesCount) {
  return {
    type: DASHBOARD_REPLACE_PROJECTS_NEW_MESSAGES_COUNT_SUCCESS,
    projectsMessagesCount,
  };
}

export function dashboardResetProjectsNewMessagesCountSuccess() {
  return {
    type: DASHBOARD_RESET_PROJECTS_NEW_MESSAGES_COUNT_SUCCESS,
  };
}

/**
 * Sync active company and project URL, redux state and session
 * !!! Must be used only during first app load
 *
 * @param dispatch
 * @param api
 * @param user
 * @param companies
 * @param getStore
 *
 * @returns {Promise<boolean>} If applied without any issues
 */
export async function applyCompanyAndProject(dispatch, api, user, companies, getStore) {
  if (companies.length === 0) {
    // Redirect to company type signup step
    dispatch(signUpEmailRestore(user));
    dispatch(push('/signup-complete'));
    return false;
  }

  dispatch(dashboardGetUserCompaniesSuccess(companies));
  dispatch(dashboardLeftMenuSetActiveItemSuccess(null));

  const currentPathname = getStore().router.location.pathname;
  let indexUserCompany = 0;
  let activeCompanyId = sessionGetLastCompanyId();

  // Get activeCompanyId from path on first app load
  Object.keys(pathRegExpsDependOnActiveCompany).forEach((name) => {
    const regexp = pathRegExpsDependOnActiveCompany[name];
    const [, , companyId] = currentPathname.match(regexp) || [];
    if (companyId) {
      dispatch(dashboardLeftMenuSetActiveItemSuccess(name));
      if (companyId !== activeCompanyId) {
        activeCompanyId = companyId;
      }
    }
  });

  // Rely on user companies, as user can be removed from a company
  if (activeCompanyId) {
    indexUserCompany = _findIndex(user.companies, { companyId: activeCompanyId });
    if (indexUserCompany === -1) {
      indexUserCompany = 0;
    }
  }

  if (user.companies[indexUserCompany]) {
    // If company saved from another user
    if (user.companies[indexUserCompany].companyId !== activeCompanyId) {
      activeCompanyId = user.companies[indexUserCompany].companyId;
    }

    let activeProjectId = sessionGetLastProjectId(activeCompanyId);

    // Get activeProjectId from path on first app load
    Object.keys(pathRegExpsDependOnActiveProject).forEach((name) => {
      const regexp = pathRegExpsDependOnActiveProject[name];
      const [, , projectId] = currentPathname.match(regexp) || [];
      if (projectId) {
        dispatch(dashboardLeftMenuSetActiveItemSuccess(name));
        if (projectId !== activeProjectId) {
          sessionSetLastProjectId(activeCompanyId, projectId);
          activeProjectId = projectId;
        }
      }
    });

    const indexCompany = activeCompanyId ? _findIndex(companies, { _id: activeCompanyId }) : 0;

    if (companies[indexCompany]) {
      dispatch(dashboardChangeActiveCompanyAllActions(activeCompanyId));
      if (activeProjectId) {
        dispatch(dashboardChangeActiveProjectIdSuccess(activeProjectId));
      }
    }
  }

  return true;
}

export function authenticate() {
  return async (dispatch, getStore, services) => {
    try {
      pusher.connect(getJwtToken());

      const [userRes, companiesRes] = await Promise.all([
        services.api.userGet(),
        services.api.userGetCompanies(),
      ]);

      const shouldContinue = await applyCompanyAndProject(
        dispatch,
        services.api,
        userRes.data,
        companiesRes.data,
        getStore
      );

      if (shouldContinue) {
        dispatch(signInCompleteUserSuccess(userRes.data));
        dispatch(appLoading(false));
      }
    } catch (err) {}
  };
}

export function getProjectsByCompanyId(companyId) {
  return async (dispatch, getStore, services) => {
    dispatch(appFetchingData(true));
    try {
      const [res, resNewMessagesCount] = await Promise.all([
        services.api.projectsGetByCompanyId(companyId),
        services.api.projectsGetNewMessagesCountByCompanyId(companyId),
      ]);
      dispatch(dashboardGetProjectsSuccess(res.data));

      const newMessagesCount = {};
      resNewMessagesCount.data.forEach((countObj) => {
        newMessagesCount[countObj._id] = countObj.newMessagesCount;
      });
      dispatch(dashboardReplaceProjectsNewMessagesCountSuccess(newMessagesCount));
      dispatch(appFetchingData(false));
    } catch (err) {
      dispatch(appFetchingData(false));
      throw err;
    }
  };
}

/**
 * Write new active project ID to session and state.
 * Redirect if necessary
 *
 * @param companyId
 * @param projectId
 * @param redirectPath
 * @returns {Function}
 */
export function changeUserActiveProjectIdAndRedirectDefault(
  companyId,
  projectId,
  redirectPath = null
) {
  return (dispatch) => {
    sessionSetLastProjectId(companyId, projectId);
    dispatch(dashboardChangeActiveProjectIdSuccess(projectId));

    if (redirectPath) {
      dispatch(push(redirectPath));
    } else if (projectId) {
      // Go to My Products by default
      dispatch(dashboardLeftMenuSetActiveItemSuccess('products'));
      dispatch(push(`/dashboard${getProjectPath(companyId, projectId)}/products`));
    }
  };
}

/**
 * Set active company from state's companies array
 * Set active company ID to session and state.
 * Change active project ID if possible
 * Redirect
 *
 * @param companyId
 * @param redirectPath
 * @returns {Function}
 */
export function changeUserActiveCompanyIdAndRedirectDefault(companyId, redirectPath = null) {
  return async (dispatch, getStore) => {
    const companies = getStore().account.companies;
    const indexCompany = _findIndex(companies, { _id: companyId });

    dispatch(dashboardChangeActiveCompanyAllActions(companyId));

    if (companies[indexCompany].type === 'client') {
      // Change active project ID
      const activeProjectId = sessionGetLastProjectId(companyId);
      dispatch(
        changeUserActiveProjectIdAndRedirectDefault(companyId, activeProjectId, redirectPath)
      );
    } else {
      dispatch(dashboardChangeActiveProjectIdSuccess(null));
      if (redirectPath) {
        dispatch(push(redirectPath));
      } else {
        // Go to Quotes by default
        dispatch(dashboardLeftMenuSetActiveItemSuccess('quotes'));
        dispatch(push(`/dashboard/company/${companyId}/quotes`));
      }
    }
  };
}

/**
 * Set active company from state's companies array
 * Set active company ID to session and state.
 *
 * @param companyId
 * @returns {Function}
 */
export function dashboardChangeActiveCompanyAllActions(companyId) {
  return async (dispatch, getStore, services) => {
    const companies = getStore().account.companies;
    const indexCompany = _findIndex(companies, { _id: companyId });

    sessionSetLastCompanyId(companyId);
    dispatch(dashboardChangeActiveCompanyIdSuccess(companyId));

    // Change state.dashboard.company
    dispatch(dashboardActiveCompanySet(companies[indexCompany]));
    dispatch(dashboardSetCompanyNewMessagesCount(0));
    try {
      const res = await services.api.companyGetNewMessagesCount(companyId);
      dispatch(dashboardIncCompanyNewMessagesCount(res.data.newMessagesCount));
    } catch (err) {}
  };
}

export function getProductsByProject(companyId, projectId) {
  return async (dispatch, getStore, services) => {
    dispatch(appFetchingData(true));
    try {
      const res = await services.api.productsGet(companyId, projectId);
      dispatch(dashboardGetProductsSuccess(res.data));

      dispatch(appFetchingData(false));
    } catch (err) {
      dispatch(appFetchingData(false));
      throw err;
    }
  };
}

export function dashboardUserInvitedAddToCompany(companyId, token) {
  return async (dispatch, getStore, services) => {
    dispatch(appFetchingData(true));
    try {
      await services.api.userPutByInvitedToken(token);
      sessionSetLastCompanyId(companyId);

      dispatch(appLoading(true));
      dispatch(appFetchingData(false));

      dispatch(signupSetInvitedTokenSuccess(null));
      dispatch(signupSetInvitedCompanySuccess(null));

      dispatch(authenticate());
    } catch (err) {
      dispatch(appFetchingData(false));
      throw err;
    }
  };
}

export function dashboardOnRouteChange() {
  return async (dispatch, getStore) => {
    const currentPath = getStore().router.location.pathname;
    let finalPathName;

    Object.keys(pathRegExpsDependOnActiveCompany).forEach((name) => {
      const regexp = pathRegExpsDependOnActiveCompany[name];
      const [, , companyId] = currentPath.match(regexp) || [];
      if (companyId) {
        finalPathName = name;
      }
    });

    Object.keys(pathRegExpsDependOnActiveProject).forEach((name) => {
      const regexp = pathRegExpsDependOnActiveProject[name];
      const [, , projectId] = currentPath.match(regexp) || [];
      if (projectId) {
        finalPathName = name;
      }
    });

    if (finalPathName && getStore().dashboard.leftMenu.activeItem !== finalPathName) {
      dispatch(dashboardLeftMenuSetActiveItemSuccess(finalPathName));
    }
  };
}
