import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { Can } from '../../util/session';
import IconCirclePlus from '../../component/icon/IconCirclePlus';
import './style.css';
import { getProductsByProject } from '../dashboard/actions';
import Products from '../../component/product/Products';
import { appFetchingData, modalClose, modalOpen } from '../../actions';
import ModalGetQuoteLink from '../../component/product/Products/ModalGetQuoteLink';
import { addCommonFormikErrorsIfEmpty, errorsTransformAxiosToFormik } from '../../util/error';
import api from '../../service/api';
import { productCreateNewSuccess } from './actions';

export class ProductsContainer extends Component {
  static propTypes = {
    basePath: PropTypes.string.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }),
  };

  static defaultProps = {
    emailQuoteBody:
      'We are using the Importist application for our product sourcing process as we believe it creates a seamless process for both of our teams. ' +
      'Please follow this link {url} to access a custom form that will allow you to quote us for our requested product.',
  };

  state = {
    modalGetQuoteLinkOpened: false,
    modalGetQuoteLinkProductId: null,
  };

  modalGetQuoteLinkOpen = (productId) => {
    this.props.modalOpen();
    this.setState({
      modalGetQuoteLinkOpened: true,
      modalGetQuoteLinkProductId: productId,
    });
  };

  modalGetQuoteLinkClose = () => {
    this.props.modalClose();
    this.setState({ modalGetQuoteLinkOpened: false, modalGetQuoteLinkProductId: null });
  };

  onProductSendEmail = async (productId, data, setSubmitting, setErrors) => {
    this.props.appFetchingData(true);
    try {
      await api.productSendEmail(this.props.companyId, this.props.projectId, productId, data);
      this.props.appFetchingData(false);
      setSubmitting(false);
      this.modalGetQuoteLinkClose();
    } catch (err) {
      this.props.appFetchingData(false);
      setSubmitting(false);
      const errors = errorsTransformAxiosToFormik(err);
      setErrors(addCommonFormikErrorsIfEmpty(errors));
    }
  };

  onProductEditClick = async (productId) => {
    this.props.history.push(`${this.props.basePath}${this.props.projectId}/product/${productId}`);
  };

  onProductViewClick = async (productId) => {
    this.props.history.push(
      `${this.props.basePath}${this.props.projectId}/product/${productId}/full`
    );
  };

  onProductDuplicateClick = async (productId) => {
    this.props.history.push(
      `${this.props.basePath}${this.props.projectId}/product/${productId}/duplicate`
    );
  };

  componentDidMount() {
    this.props.getProductsByProject(this.props.companyId, this.props.projectId);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.lastChangedProduct !== null &&
      this.props.lastChangedProduct.status === 'complete'
    ) {
      this.modalGetQuoteLinkOpen(this.props.lastChangedProduct._id);
      this.props.resetStateNewProduct();
    }
    if (this.props.projectId && prevProps.projectId !== this.props.projectId) {
      this.props.getProductsByProject(this.props.companyId, this.props.projectId);
    }
  }

  render() {
    return (
      <div className="products-container table-container">
        <label className="page-header">My Products</label>
        <Can I="add" a="Product">
          <button
            onClick={() => {
              this.props.history.push(`${this.props.basePath}${this.props.projectId}/products/add`);
            }}
            className="btn white-button btn-top-right add-product"
          >
            <div className={'icon'}>
              <IconCirclePlus width={19} height={19} color={'var(--common-text-color)'} />
            </div>
            <span className={'text'}>Add Product</span>
          </button>
        </Can>
        <Products
          products={this.props.products}
          modalGetQuoteLinkOpen={this.modalGetQuoteLinkOpen}
          onProductEditClick={this.onProductEditClick}
          onProductViewClick={this.onProductViewClick}
          onProductDuplicateClick={this.onProductDuplicateClick}
          history={this.props.history}
          activeCompanyId={this.props.companyId}
          activeProjectId={this.props.projectId}
        />
        {this.state.modalGetQuoteLinkOpened && (
          <ModalGetQuoteLink
            productId={this.state.modalGetQuoteLinkProductId}
            emailBody={this.props.emailQuoteBody}
            onClose={this.modalGetQuoteLinkClose}
            onSubmit={this.onProductSendEmail}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  companyId: state.dashboard.activeCompanyId,
  projectId: state.dashboard.activeProjectId,
  lastChangedProduct: state.dashboard.lastChangedProduct,
  products: state.dashboard.products,
});

const mapDispatchToProps = (dispatch) => ({
  modalOpen: () => dispatch(modalOpen()),
  modalClose: () => dispatch(modalClose()),
  appFetchingData: (is) => dispatch(appFetchingData(is)),
  getProductsByProject: (companyId, projectId) =>
    dispatch(getProductsByProject(companyId, projectId)),
  resetStateNewProduct: () => dispatch(productCreateNewSuccess(null)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ProductsContainer)
);
